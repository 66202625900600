import React from "react";
import { therapists } from "../../helper/data.helper";
import ListingPage from "./ListingPage";

const TherapistList = () => {
  return (
    <>
      <div className="outer-div flex-container">
        <div className="inner-div">
          {therapists.map((therapist, index) => (
            <ListingPage therapist={therapist} key={therapist.id} />
          ))}
        </div>
        <style jsx>
          {`
            .outer-div {
              // margin-top: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .inner-div {
              width: 70%;
            }
            @media only screen and (max-width: 1050px) {
              .inner-div {
                width: 80%;
              }
            }

            @media only screen and (max-width: 950px) {
              .inner-div {
                width: 95%;
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default TherapistList;
