import { Button } from "@mui/material";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doc, onSnapshot } from "firebase/firestore";

import { initCourseAssessment } from "../../../../helper/api.helper";
import Loader from "../../../../components/loader/Loader";
import { database } from "../../../../helper/firebase.helper";

const Assessment = ({ selectedCourse, setVisitedPages }) => {
  const [isLoading, setLoading] = useState(false);
  const [activity, setActivity] = useState();
  const sectionDetails = selectedCourse?.course.sections?.[0];
  const user = useStoreState((state) => state.user);

  function startAssessment() {
    setLoading(true);
    if (user) {
      initCourseAssessment(selectedCourse.courseActivity.id)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            setLoading(false);
            window.location.href = res.assessmentUrl;
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    } else {
      setLoading(false);
      toast.error("Failed to initiate assessment");
    }
  }

  useEffect(() => {
    if (selectedCourse.courseActivity.assessment_completion_time) {
      setVisitedPages((prevState) => ({
        ...prevState,
        assessment: true,
      }));
    }
  }, []);

  useEffect(() => {
    const unSub = onSnapshot(doc(database, "activities", selectedCourse.courseActivity.id), (doc) => {
      setActivity(doc.data())
  });
  return () => {
    unSub && unSub();
  }
  }, [])

  return (
    <div className="assessment-wrapper">
      {isLoading ? <Loader /> : null}
      <h4>{sectionDetails?.name}</h4>
      <div className="margin-top--md">
        {sectionDetails?.body.map((section) => (
           section.type === 'points' ? (<ul>{section.text.map((text) => (<li className="margin-top--sm" key={text}>{text}</li>))}</ul>) : section.text.map((text) => (<p className="margin-top--sm" key={text}>{text}</p>))
        ))}
      </div>
      {!activity ? <></> :
      activity?.report_url ? (
        <Button
          href={activity?.report_url}
          target="_blank"
          variant="contained"
          color="primary"
          sx={{ maxHeight: "30px", margin: "20px 0px" }}
        >
          View report
        </Button>
      ) : activity?.assessment_url && activity?.assessment_submitted ? (
        <p className="margin-top--md margin-bottom--md gray-text">
          Report being generated...
        </p>
      ) : activity?.assessment_url ? (
        <Button
          href={activity?.assessment_url}
          variant="contained"
          color="primary"
          sx={{ maxHeight: "30px", margin: "20px 0px" }}
        >
          Continue assessment
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          sx={{ maxHeight: "30px", margin: "20px 0px" }}
          onClick={startAssessment}
        >
          Start assessment
        </Button>
      )}

      <style jsx>
        {`
          .assessment-wrapper {
            padding: 0px 40px;
          }
          @media only screen and (max-width: 600px) {
            .assessment-wrapper {
              padding: 0px 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Assessment;
