import { database } from "../../../helper/firebase.helper";
import { doc, setDoc, getDoc } from "firebase/firestore";

export const fetchFieldsFromDb = async (orgId) => {
  try {
    const docRef = doc(
      database,
      `organisations/${orgId}/details/custom_fields`
    );
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().fields;
    } else {
      console.log("No such document!");
      return [];
    }
  } catch (error) {
    console.error("Error fetching document: ", error.message);
    return [];
  }
};

export const writeClientDetailsToDb = async (details, clientId) => {
  try {
    const docRef = doc(database, `clients/${clientId}/details/custom_fields`);
    await setDoc(docRef, details);
    console.log("Details written to Firestore");
  } catch (error) {
    console.error("Error writing document: ", error.message);
  }
};

export const fetchClientDetailsFromDb = async (clientId) => {
  try {
    const docRef = doc(database, `clients/${clientId}/details/custom_fields`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return {};
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
    return {};
  }
};
