import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import toast from "react-hot-toast";
import {
  deleteObject,
  getMetadata,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../helper/firebase.helper";

const Assignment = ({ selectedCourse, setVisitedPages, visitedPages }) => {
  const [fileName, setFileName] = useState("");
  const sectionDetails = selectedCourse?.course.sections?.[3];

  const handleDownload = async () => {
    try {
      const response = await fetch(selectedCourse?.course.assignment);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const filename = decodeURIComponent(selectedCourse?.course.assignment).split('#')[0].split('?')[0].split('/').pop();

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.files[0]?.size > 5242880) {
        toast.error(`File size shouldn't exceed 5MB`);
        event.target.files = "";
        return;
      } else {
        try {
          const listRef = ref(
            storage,
            `activities/${selectedCourse.courseActivity.id}/assignment`
          );

          const res = await listAll(listRef);

          for (const itemRef of res.items) {
            await deleteObject(itemRef);
          }

          const storageRef = ref(
            storage,
            `activities/${selectedCourse.courseActivity.id}/assignment/${file.name}`
          );

          await uploadBytes(storageRef, file);

          toast.success("Assignment uploaded successfully!");

          setVisitedPages((prevState) => ({
            ...prevState,
            assignment: true,
          }));
        } catch (error) {
          toast.error("Failed to upload assignment");
          console.error("Error uploading file:", error);
        }
      }
    }
  };

  useEffect(() => {
    const fetchFileName = async () => {
      try {
        const listRef = ref(
          storage,
          `activities/${selectedCourse.courseActivity.id}/assignment`
        );
        const res = await listAll(listRef);
        if (res.items.length > 0) {
          const fileRef = res.items[0];
          const metadata = await getMetadata(fileRef);
          setFileName(metadata.name);
        }
      } catch (error) {
        console.error("Error listing files:", error);
      }
    };
    fetchFileName();
  }, [selectedCourse.courseActivity.id, visitedPages]);

  return (
    <div className="assignment-wrapper">
      <h4>{sectionDetails.name}</h4>
      <div className="margin-top--md">
        {sectionDetails?.body.map((section) => (
           section.type === 'points' ? (<ul>{section.text.map((text) => (<li className="margin-top--sm" key={text}>{text}</li>))}</ul>) : section.text.map((text) => (<p className="margin-top--sm" key={text}>{text}</p>))
        ))}
      </div>

      <Button
        color="primary"
        sx={{ maxHeight: "30px", textTransform: "none" }}
        endIcon={<FileDownloadOutlinedIcon />}
        onClick={handleDownload}
      >
        {decodeURIComponent(selectedCourse?.course.assignment).split('#')[0].split('?')[0].split('/').pop()}
      </Button>

      <div>
        <input
          type="file"
          accept="application/pdf"
          id="upload-file-input"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <label htmlFor="upload-file-input">
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{
              maxHeight: "30px",
              marginTop: "20px",
              textTransform: "none",
              marginBottom: "15px",
            }}
            endIcon={<FileUploadOutlinedIcon />}
          >
            {fileName ? "Change assignment " : " Upload assignment"}
          </Button>
        </label>
        {fileName && (
          <span style={{ marginLeft: "10px", marginTop: "20px" }}>
            {fileName
              ? fileName.length > 30
                ? fileName.slice(0, 30) + "..."
                : fileName
              : null}
          </span>
        )}
      </div>

      <p style={{ margin: "0px 0px 20px 0px" }}>
        <span style={{ fontSize: "8px", color: "#616161" }}>
          Only PDF upto 5MBs can be uploaded.
        </span>
      </p>
      <style jsx>
        {`
          .assignment-wrapper {
            padding: 0px 40px;
          }
          ul li {
            line-height: 25px;
          }
          @media only screen and (max-width: 600px) {
            .assignment-wrapper {
              padding: 0px 20px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Assignment;
