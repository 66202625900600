import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import RecommendOutlinedIcon from "@mui/icons-material/RecommendOutlined";
import { initSelfAssessment } from "../../helper/api.helper";
import toast from "react-hot-toast";
import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Loader from "../../components/loader/Loader";

const SelfAssessment = ({ assessments = [] }) => {
  const [isLoading, setLoading] = useState(false);
  const [showAllAssessments, setShowAllAssessments] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 780);
  const user = useStoreState((state) => state.user);
  const navigate = useNavigate();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 780);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const initAssessment = (assessmentId) => {
    setLoading(true);

    if (user) {
      initSelfAssessment(assessmentId)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            setLoading(false);
            window.location.href = res.assessmentUrl;
          }
        });
    } else {
      setLoading(false);
      toast.error("Please login to access assessments");
      navigate("/login");
    }
  };

  const displayedAssessments = showAllAssessments
    ? assessments
    : assessments.slice(0, 3);

  return (
    <div className="margin-top--xl">
      {isLoading ? <Loader /> : null}

      <h2 style={{ fontWeight: "normal" }}>SELF-ASSESSMENT</h2>
      <h2 className="margin-top--xs">Not Sure ? Take a Mental Health Test</h2>
      <p>
        Online screening is one of the quickest and easiest ways to determine
        whether you are experiencing symptoms of a mental health condition and
        if you should seek help. Use our confidential self-assessment tools to
        evaluate your mental health and receive personalized feedback on your
        emotional and psychological well-being.
      </p>
      <div className="carousel-container">
        {isMobile && (
          <Swiper
            modules={[Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
          >
            {assessments.map((assessment, index) => (
              <SwiperSlide key={assessment.assessment_id}>
                <div
                  key={assessment.id}
                  onClick={() => initAssessment(assessment.id)}
                  className="carousel-card margin-top--lg"
                >
                  <div className="image-div">
                    <img
                      alt="assessment-pic"
                      src={assessment.logo}
                      height={150}
                    />
                  </div>
                  <div className="assessment-details">
                    <h3 style={{ marginTop: "-10px" }}>
                      <span
                        style={{
                          borderBottom: "1px solid lightgrey",
                          paddingBottom: "10px",
                          color: "black",
                        }}
                      >
                        {assessment.name}
                      </span>
                    </h3>
                    <div className="flex-container margin-top--lg">
                      <RecommendOutlinedIcon fontSize="small" color="primary" />
                      <p style={{ margin: "-1px 0px 0px 5px" }}>
                        {assessment.eligibility}
                      </p>
                    </div>
                    <div className="flex-container margin-top--sm">
                      <HourglassEmptyIcon fontSize="small" color="primary" />
                      <p style={{ margin: "-1px 0px 0px 5px" }}>
                        {assessment.duration}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div className="flex-container  list-div">
        {!isMobile &&
          displayedAssessments.map((assessment, index) => (
            <div
              key={assessment.id}
              onClick={() => initAssessment(assessment.id)}
              className="assessment-div margin-top--lg"
            >
              <div className="image-div">
                <img alt="assessment-pic" src={assessment.logo} height={150} />
              </div>
              <div className="assessment-details">
                <h3 style={{ marginTop: "-10px" }}>
                  <span
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingBottom: "10px",
                      color: "black",
                    }}
                  >
                    {assessment.name}
                  </span>
                </h3>
                <div className="flex-container margin-top--lg">
                  <RecommendOutlinedIcon fontSize="small" color="primary" />
                  <p style={{ margin: "-1px 0px 0px 5px" }}>
                    {assessment.eligibility}
                  </p>
                </div>
                <div className="flex-container margin-top--sm">
                  <HourglassEmptyIcon fontSize="small" color="primary" />
                  <p style={{ margin: "-1px 0px 0px 5px" }}>
                    {assessment.duration}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>

      {!isMobile && assessments && assessments.length > 3 && (
        <Button
          // href=""
          variant="contained"
          color="primary"
          // size="small"
          sx={{ textTransform: "none", marginTop: "20px" }}
          onClick={() => setShowAllAssessments((prev) => !prev)}
        >
          {showAllAssessments ? "Show less" : "View all assessments"}
        </Button>
      )}

      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .assessment-div {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          width: 320px;
          overflow: hidden;
          margin-right: 5%;
        }
        .assessment-div:hover {
          background-color: #f4f4f4;
        }
        .carousel-card {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          min-width: 280px;
          width: 320px;
          overflow: hidden;
          // margin-right: 2%;
        }
        .carousel-card:hover {
          background-color: #f4f4f4;
        }
        .carousel-container {
          max-width: 322px;
          margin: auto;
        }

        .image-div img {
          object-fit: cover;
        }
        .assessment-details {
          padding: 25px 20px;
        }
        @media only screen and (max-width: 1025px) {
          .list-div {
            justify-content: left;
          }
          .assessment-div {
            margin-right: 5%;
          }
        }
      `}</style>
    </div>
  );
};

export default SelfAssessment;
