import { Button } from "@mui/material";
import React from "react";
import TimeslotSelect from "./TimeslotSelect";
import DateSelect from "./DateSelect";
import SessionSelect from "./SessionSelect";
import LocationSelect from "./LocationSelect";

const ConsultationDetails = ({
  selectedEventType,
  handleEventTypeChange,
  selectedLocation,
  handleLocationChange,
  therapist,
  selectedDate,
  handleDateClick,
  selectedTimeIndex,
  handleTimeClick,
  handleBook,
  formatDate,
}) => {
  return (
    <>
      <div>
        <SessionSelect
          selectedEventType={selectedEventType}
          handleEventTypeChange={handleEventTypeChange}
        />

        <LocationSelect
          selectedLocation={selectedLocation}
          handleLocationChange={handleLocationChange}
          therapist={therapist}
        />

        <DateSelect
          selectedDate={selectedDate}
          handleDateClick={handleDateClick}
        />
      </div>
      <div>
        <TimeslotSelect
          selectedTimeIndex={selectedTimeIndex}
          handleTimeClick={handleTimeClick}
        />
      </div>
      <div style={{ textAlign: "start", marginLeft: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleBook}
          sx={{ textTransform: "none" }}
        >
          Book session for {formatDate(selectedDate)}
        </Button>
      </div>
      <style jsx>{`
        p,
        h3,
        h4,
        .main-div > span,
        div {
          color: black;
        }
      `}</style>
    </>
  );
};

export default ConsultationDetails;
