import React from "react";
import { eventTypes } from "../../helper/data.helper";
import { MenuItem, Select } from "@mui/material";

const SessionSelect = ({ selectedEventType, handleEventTypeChange }) => {
  return (
    <>
      <h4>Choose your session type.</h4>
      <Select
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        value={selectedEventType}
        onChange={handleEventTypeChange}
        displayEmpty
        // size="small"
        sx={{
          marginTop: "6px",
          marginBottom: "8px",
          minWidth: `${window.innerWidth > 825 ? "340px" : "270px"}`,
        }}
      >
        {/* <MenuItem value="" disabled sx={{ minWidth: "340px" }}>
                  Select Event Type
                </MenuItem> */}
        {/* {eventTypes.map((type, index) => (
                  <MenuItem key={index} value={type} sx={{ minWidth: "340px" }}>
                    {type}
                  </MenuItem>
                ))} */}
        {eventTypes.map((type, index) => {
          const [eventType, duration] = type.split(" - ");
          return (
            <MenuItem key={type} value={type} sx={{ minWidth: "340px" }}>
              <span style={{ marginRight: "8px" }}>{eventType}</span>
              <span
                style={{
                  backgroundColor: "rgb(216 230 253)",
                  padding: "3px 6px",
                  borderRadius: "5px",
                }}
              >
                {duration}
              </span>
            </MenuItem>
          );
        })}
      </Select>
      <style jsx>
        {`
          h4 {
            color: black;
          }
        `}
      </style>
    </>
  );
};

export default SessionSelect;
