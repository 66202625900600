import React from "react";
import TIETCoverImage from "../../../assets/TIETCoverImage.png";
import { useStoreState } from "easy-peasy";

const InstituteDetails = () => {
  const banner = useStoreState((state) => state.banner);

  return (
    <div className="institute-details">
      <div className="cover-div">
        <img src={banner} alt="Cover" />
      </div>

      <style jsx>{``}</style>
    </div>
  );
};

export default InstituteDetails;
