import React, { useEffect, useState } from "react";
import { useStoreState } from "easy-peasy";
import { Helmet } from "react-helmet";
import { TextField, MenuItem, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import Loader from "../../../components/loader/Loader";
import {
  fetchClientDetailsFromDb,
  fetchFieldsFromDb,
  writeClientDetailsToDb,
} from "./profile.service";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [fields, setFields] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { control, handleSubmit, reset, setValue } = useForm();

  const user = useStoreState((state) => state.user);

  // console.log(user);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      if (user?.orgId) {
        const fetchedFields = await fetchFieldsFromDb(user.orgId);
        setFields(fetchedFields);
        setLoading(false);
      }
      setLoading(false);
    };

    fetchData();
  }, [user, setValue]);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      if (user?.uid) {
        const clientDetails = await fetchClientDetailsFromDb(user.uid);
        for (const [key, value] of Object.entries(clientDetails)) {
          setValue(key, value);
        }
        setLoading(false);
      }
      setLoading(false);
    };

    fetchData();
  }, [user, setValue]);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      await writeClientDetailsToDb(data, user?.uid);
      toast.success("Details saved successfully");
      //   reset();
    } catch (error) {
      toast.error("Error saving client details: ", error.message);
      console.log(error.message);
    }
  };

  return (
    <div className="profile-wrapper">
      <Helmet>
        <title>Profile - DeTalks</title>
      </Helmet>
      {isLoading ? <Loader /> : null}
      <div className="details-div">
        <p>{user.displayName ? `Name - ${user.displayName}` : null}</p>
        <p className="margin-top--sm">
          {user.email ? `Email - ${user.email}` : null}
        </p>
        <p className="margin-top--sm">
          {user.phoneNumber ? (
            `Number - ${user.phoneNumber}`
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={() => navigate("/register/register-number")}
            >
              Add Phone Number
            </Button>
          )}
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="fields-div margin-top--md">
          {fields?.map((field) => (
            <Controller
              key={field.id}
              name={field.id}
              control={control}
              defaultValue=""
              rules={{
                required: field.mandatory
                  ? `Please fill the ${field.name}`
                  : false,

                ...(field.type === 4 && {
                  pattern: {
                    value: /^(?:\+91)?\d{10}$/,
                    message: "Please enter a valid phone number",
                  },
                }),
                ...(field.type === 5 && {
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: "Please enter a valid email address",
                  },
                }),
                ...(field.ends_with && {
                  validate: (val) => 
                    val.endsWith(field.ends_with) ? true : `Please enter organisation email ending with ${field.ends_with}`,
                }),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  {field.type === 0 ? (
                    <TextField
                      type="text"
                      label={field.name}
                      placeholder={field.name}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      size="small"
                      multiline={!!field.rows}
                      rows={field.rows}
                    />
                  ) : field.type === 1 ? (
                    <TextField
                      type="number"
                      label={field.name}
                      placeholder={field.placeholder}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      size="small"
                    />
                  ) : field.type === 2 ? (
                    <TextField
                      select
                      label={field.name}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 400,
                            },
                          },
                        },
                      }}
                      size="small"
                    >
                      {field.values.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : field.type === 3 ? (
                    <TextField
                      type="date"
                      label={field.name}
                      placeholder={field.placeholder}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      size="small"
                      // InputLabelProps={{ shrink: true }}
                    />
                  ) : field.type === 4 ? (
                    <TextField
                      type="tel"
                      label={field.name}
                      placeholder={field.placeholder}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      size="small"
                    />
                  ) : field.type === 5 ? (
                    <TextField
                      type="email"
                      label={field.name}
                      placeholder={field.placeholder}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : ""}
                      size="small"
                    />
                  ) : null}
                </>
              )}
            />
          ))}
        </div>
        {fields && fields.length > 0 && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="small"
          >
            Save
          </Button>
        )}
      </form>
      <style jsx>
        {`
          .profile-wrapper {
            min-height: 90vh;
            padding: 20px;
          }
          .details-div {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 20px;
          }
          .fields-div {
            max-width: 400px;
            margin-bottom: 20px;
          }
        `}
      </style>
    </div>
  );
};

export default Profile;
