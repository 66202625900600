import firebaseApp from "firebase/compat/app";
import "firebase/compat/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getPerformance } from "firebase/performance";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

const app = firebaseApp.initializeApp(firebaseConfig);

const auth = app.auth();
const database = getFirestore(app);
const storage = getStorage(app);
const performance = getPerformance(app);

export { auth, database, performance, storage, app };
