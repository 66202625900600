import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { therapists } from "../../helper/data.helper";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ChooseTherapist = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 780);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 780);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="margin-top--lg">
      <h2 style={{ fontWeight: "normal" }}>COUNSELLING AND THERAPY</h2>
      <h2 className="margin-top--xs">
        Find the Right Therapist and Start Getting Support
      </h2>
      <p>
        Receive expert help from licensed psychologists and psychiatrists for
        stress, anxiety, depression, heartbreak, loneliness, relationship
        issues, sleep troubles, body image concerns, smoking cessation, trauma,
        and more. Our professional therapists provide personalized care to
        support your journey towards mental wellness.
      </p>
      <div className="carousel-container">
        {isMobile && (
          <Swiper
            modules={[Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
          >
            {therapists?.slice(0, 3).map((therapist, index) => (
              <SwiperSlide key={therapist.id}>
                <div
                  key={therapist.id}
                  className="carousel-card margin-top--lg"
                >
                  <Link
                    to={`/homepage/therapist/${therapist.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className="image-div">
                      <img alt="profile" src={therapist.photo} />
                    </div>
                    <div className="flex-container justify-between">
                      <h3>
                        {" "}
                        {therapist.name
                          ? therapist.name.length > 15
                            ? therapist.name.slice(0, 15) + "..."
                            : therapist.name
                          : null}
                      </h3>
                      <p className="margin-top--lg">
                        <VideocamOutlinedIcon
                          fontSize="small"
                          color="primary"
                          sx={{
                            backgroundColor: "rgb(216 230 253)",
                            padding: 0.2,
                            marginTop: "-5px",
                            borderRadius: "10%",
                          }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <ChairOutlinedIcon
                          fontSize="small"
                          color="primary"
                          sx={{
                            backgroundColor: "rgb(216 230 253)",
                            padding: 0.2,
                            marginTop: "-5px",
                            borderRadius: "10%",
                          }}
                        />
                      </p>
                    </div>
                    <p>
                      <span
                        style={{
                          borderBottom: "1px solid lightgrey",
                          paddingBottom: "7px",
                          color: "black",
                        }}
                      >
                        {therapist.title
                          ? therapist.title.length > 25
                            ? therapist.title.slice(0, 25) + "..."
                            : therapist.title
                          : null}
                      </span>
                    </p>
                    <div className="margin-top--md">
                      <div className="flex-container margin-top--sm">
                        <StarBorderIcon fontSize="small" color="primary" />
                        <p style={{ margin: "-1px 0px 0px 5px" }}>
                          {therapist.specialties
                            ? therapist.specialties.length > 30
                              ? therapist.specialties.slice(0, 30) + "..."
                              : therapist.specialties
                            : null}
                        </p>
                      </div>
                      <div className="flex-container margin-top--sm">
                        <PeopleAltOutlinedIcon
                          fontSize="small"
                          color="primary"
                        />
                        <p style={{ margin: "-1px 0px 0px 5px" }}>
                          {therapist.worksWith
                            ? therapist.worksWith.length > 30
                              ? therapist.worksWith.slice(0, 30) + "..."
                              : therapist.worksWith
                            : null}
                        </p>
                      </div>
                      <div className="flex-container margin-top--sm">
                        <LanguageIcon fontSize="small" color="primary" />
                        <p style={{ margin: "-1px 0px 0px 5px" }}>
                          {therapist.languages
                            ? therapist.languages.length > 30
                              ? therapist.languages.slice(0, 30) + "..."
                              : therapist.languages
                            : null}
                        </p>
                      </div>

                      <p className="margin-top--sm">
                        Next Available: 26th June, 7:30 pm
                      </p>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className="flex-container  list-div">
        {!isMobile &&
          therapists?.slice(0, 3).map((therapist, index) => (
            <div key={therapist.id} className="therapist-div margin-top--lg">
              <Link
                to={`/homepage/therapist/${therapist.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-div">
                  <img alt="profile" src={therapist.photo} />
                </div>
                <div className="flex-container justify-between">
                  <h3>
                    {" "}
                    {therapist.name
                      ? therapist.name.length > 15
                        ? therapist.name.slice(0, 15) + "..."
                        : therapist.name
                      : null}
                  </h3>
                  <p className="margin-top--lg">
                    <VideocamOutlinedIcon
                      fontSize="small"
                      color="primary"
                      sx={{
                        backgroundColor: "rgb(216 230 253)",
                        padding: 0.2,
                        marginTop: "-5px",
                        borderRadius: "10%",
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <ChairOutlinedIcon
                      fontSize="small"
                      color="primary"
                      sx={{
                        backgroundColor: "rgb(216 230 253)",
                        padding: 0.2,
                        marginTop: "-5px",
                        borderRadius: "10%",
                      }}
                    />
                  </p>
                </div>
                <p>
                  <span
                    style={{
                      borderBottom: "1px solid lightgrey",
                      paddingBottom: "7px",
                      color: "black",
                    }}
                  >
                    {therapist.title
                      ? therapist.title.length > 25
                        ? therapist.title.slice(0, 25) + "..."
                        : therapist.title
                      : null}
                  </span>
                </p>
                <div className="margin-top--md">
                  <div className="flex-container margin-top--sm">
                    <StarBorderIcon fontSize="small" color="primary" />
                    <p style={{ margin: "-1px 0px 0px 5px" }}>
                      {therapist.specialties
                        ? therapist.specialties.length > 30
                          ? therapist.specialties.slice(0, 30) + "..."
                          : therapist.specialties
                        : null}
                    </p>
                  </div>
                  <div className="flex-container margin-top--sm">
                    <PeopleAltOutlinedIcon fontSize="small" color="primary" />
                    <p style={{ margin: "-1px 0px 0px 5px" }}>
                      {therapist.worksWith
                        ? therapist.worksWith.length > 30
                          ? therapist.worksWith.slice(0, 30) + "..."
                          : therapist.worksWith
                        : null}
                    </p>
                  </div>
                  <div className="flex-container margin-top--sm">
                    <LanguageIcon fontSize="small" color="primary" />
                    <p style={{ margin: "-1px 0px 0px 5px" }}>
                      {therapist.languages
                        ? therapist.languages.length > 30
                          ? therapist.languages.slice(0, 30) + "..."
                          : therapist.languages
                        : null}
                    </p>
                  </div>

                  <p className="margin-top--sm">
                    Next Available: 26th June, 7:30 pm
                  </p>
                </div>
              </Link>
            </div>
          ))}
      </div>
      <Link
        to={`therapist-list`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Button
          variant="contained"
          color="primary"
          // size="small"
          //   onClick={}
          sx={{ textTransform: "none", marginTop: "20px" }}
        >
          Browse all therapist
        </Button>
      </Link>
      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .therapist-div {
          padding: 16px;
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          margin-right: 5%;
          max-width: 320px;
        }
        .therapist-div:hover {
          background-color: #f4f4f4;
        }
        .carousel-card {
          padding: 16px;
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          // margin-right: 5%;
          max-width: 320px;
        }
        .carousel-card:hover {
          background-color: #f4f4f4;
        }
        .carousel-container {
          max-width: 320px;
          margin: auto;
        }
        .image-div {
          width: 150px;
          height: 150px;
          overflow: hidden;
          border-radius: 50%;
        }
        .image-div img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
        @media only screen and (max-width: 1025px) {
          .list-div {
            justify-content: left;
          }
          .therapist-div {
            margin-right: 5%;
          }
        }
        @media only screen and (max-width: 780px) {
          .carousel-card {
            padding: 16px 16px 25px 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default ChooseTherapist;
