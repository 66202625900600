import React from "react";
import { Link } from "react-router-dom";
import PictureDetails from "./PictureDetails";
import Details from "./Details";

const ListingPage = ({ therapist }) => {
  return (
    <>
      <Link
        to={`/homepage/therapist/${therapist.id}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div className="listing-div flex-container">
          <div className="image-outer-div flex-container">
            <PictureDetails therapist={therapist} />
          </div>
          <div className="details-div">
            <Details therapist={therapist} />
          </div>
        </div>
      </Link>
      <style jsx>
        {`
          .listing-div {
            display: flex;
            border-bottom: 1px solid lightgrey;
            padding: 15px 10px;
            justify-content: space-evenly;
          }
          .listing-div:hover {
            background-color: #f4f4f4;
            border-radius: 5px;
          }
          .image-outer-div {
            display: flex;
            justify-content: center;
            align-items: center;
            // padding-left: 10px;
            // width: 20%;
          }

          .details-div {
            // width: 60%;
            padding: 10px;
          }

          @media only screen and (max-width: 760px) {
            .listing-div {
              flex-direction: column;
            }
          }
          @media only screen and (max-width: 500px) {
            .image-outer-div {
              justify-content: start;
              padding-left: 10px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ListingPage;
