import React, { useRef } from "react";
import { Button, MenuItem } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { Hidden, IconButton, Grow, MenuList, Paper } from "@mui/material";
import style from "./Topnav.module.scss";
import { auth } from "../../helper/firebase.helper";
import Logo from "../logocontainers/logocontainer";
import { KeyboardArrowDown } from "@mui/icons-material";
import Account from "../account/Account";
import { Link, useMatch, useNavigate } from "react-router-dom";

const TopNav = ({ showUser, handleSidebar }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);

  const navigate = useNavigate();

  const login = useMatch("/login/*");
  // const register = useMatch("/register/*");
  const dashboard = useMatch("/dashboard/*");

  const innerDivStyle = dashboard ? {} : { maxWidth: "1150px" };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <div className={style.navWrapper}>
      <div className={style.innerDiv} style={innerDivStyle}>
        <Logo handleSidebar={handleSidebar} />
        {!auth.currentUser && (
          <>
            {login ? (
              <Button onClick={() => navigate("/homepage")}>Home</Button>
            ) : (
              <Button onClick={() => navigate("/login")}>Login</Button>
            )}
          </>
        )}
        {showUser && auth.currentUser && (
          <div className={style.dropdown}>
            {dashboard ? (
              <Button onClick={() => navigate("/homepage")}>Home</Button>
            ) : (
              <Button onClick={() => navigate("/dashboard")}>Dashboard</Button>
            )}

            <Hidden
              // smDown
              implementation="css"
            >
              <Account {...{ showUser }} />
            </Hidden>
            <IconButton onClick={handleToggle}>
              <KeyboardArrowDown ref={anchorRef} />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role="button"
              transition
              disablePortal
              placement="bottom-end"
              style={{
                minWidth: "150px",
              }}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: "center top" }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={() => auth.signOut()}>
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNav;
