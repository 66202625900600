import React from "react";
import ISO1 from "../assets/9001.svg";
import ISO2 from "../assets/27001.svg";
import hipaa from "../assets/HIPAA.svg";

const Footer = () => {
  return (
    <div className="footer flex-container justify-between">
      <div className="flex-container terms-div">
        <b>Terms</b>
        <b>Privacy</b>
      </div>
      <div className="flex-container icon-div">
        <img src={ISO1} alt="ISO 9001:2015" height="14px" />
        <img src={ISO2} alt="ISO 9001:2015" height="14px" />
        <img src={hipaa} alt="ISO 9001:2015" height="23px" />
      </div>
      <style jsx>
        {`
          .footer {
            padding: 20px 16px;
            // height: 50px;
            margin-top: auto;
            background-color: #f7f7f7;
          }
          b {
            margin-left: 10px;
          }
          div img {
            margin-right: 10px;
          }

          @media only screen and (max-width: 600px) {
            .footer {
              flex-direction: column;
              padding: 10px 8px;
              // margin-bottom: 50px;
            }
            .terms-div {
              justify-content: center;
              margin-bottom: 5px;
            }
            .icon-div {
              justify-content: space-between;
            }
            div img {
              margin-right: 0px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Footer;
