import React from "react";

const ImageDetails = ({ therapist }) => {
  return (
    <>
      <div className="img-div">
        <img
          alt="profile"
          src={therapist.photo}
          // width={100}
          // height={100}
        />
      </div>
      <div className="name-div" style={{ padding: "0px 15px 0px 0px" }}>
        <h3 style={{ fontSize: "23px" }}> {therapist.name}</h3>
        <p style={{ fontSize: "17px" }}>{therapist.title}</p>
      </div>

      <style jsx>{`
        p,
        h3,
        div {
          color: black;
        }
        .img-div {
          width: 200px;
          height: 200px;
          margin: -50px 40px 0px 0px;
          overflow: hidden;
          border-radius: 50%;
        }
        .img-div img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        @media only screen and (max-width: 431px) {
          .img-div {
            width: 140px;
            height: 140px;
            margin: -50px 10px 0px 0px;
          }
          .img-div img {
            width: 100%;
            height: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default ImageDetails;
