import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
  if (window)
      // @ts-expect-error This does not exist outside of polyfill which this is doing
      window.Promise.withResolvers = function () {
          let resolve, reject;
          const promise = new Promise((res, rej) => {
              resolve = res;
              reject = rej;
          });
          return { promise, resolve, reject };
      };
}
pdfjs.GlobalWorkerOptions.workerSrc="https://unpkg.com/pdfjs-dist@4.4.168/legacy/build/pdf.worker.min.mjs";

const PdfPage = ({ selectedCourse, setVisitedPages }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const sectionDetails = selectedCourse?.course.sections?.[1];

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const updateScale = () => {
    const width = window.innerWidth;

    if (width <= 450) {
      setScale(0.4);
    } else if (width <= 540) {
      setScale(0.5);
    } else if (width <= 600) {
      setScale(0.6);
    } else if (width <= 670) {
      setScale(0.5);
    } else if (width <= 750) {
      setScale(0.6);
    } else if (width <= 820) {
      setScale(0.7);
    } else {
      setScale(0.8);
    }
  };

  useEffect(() => {
    setVisitedPages((prevState) => ({
      ...prevState,
      pdf: true,
    }));
  }, []);

  useEffect(() => {
    updateScale();
    window.addEventListener("resize", updateScale);

    return () => {
      window.removeEventListener("resize", updateScale);
    };
  }, [scale]);

  const handleDownload = async () => {
    try {
      const response = await fetch(selectedCourse?.course.resourceUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const title = selectedCourse?.course.name.substring(0, 30).replace(/\s+/g, "_");
      const filename = `${title}.pdf`;

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <div className="pdf-wrapper">
      <h4>{sectionDetails.name}</h4>
      {selectedCourse && selectedCourse.course.resourceUrl && (
        <div className="pdf-viewer margin-top--lg flex-container direction-column ">
          <Document
            file={selectedCourse.course.resourceUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} className="pdf-page" scale={scale} />
          </Document>
          <div style={{ textAlign: "center" }}>
            <IconButton
              disabled={pageNumber <= 1}
              onClick={previousPage}
              color="primary"
            >
              <ArrowBack />
            </IconButton>
            <span>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </span>
            <IconButton
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              color="primary"
            >
              <ArrowForward />
            </IconButton>
          </div>
        </div>
      )}
      <Button
        style={{ marginTop: "20px", textTransform: "none" }}
        endIcon={<FileDownloadOutlinedIcon color="primary" />}
        onClick={handleDownload}
      >
        {decodeURIComponent(selectedCourse?.course.resourceUrl).split('#')[0].split('?')[0].split('/').pop()}
      </Button>
      <style jsx>
        {`
          .pdf-wrapper {
            padding: 0px 40px;
          }
          .pdf-viewer {
            border: 1px solid lightgrey;
            padding: 10px 0px;

            width: 100%;
            height: auto;
            overflow: scroll;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          .pdf-page {
            width: 100%;
            height: auto;
          }

          span {
            margin: 0px 10px;
          }
        `}
      </style>
    </div>
  );
};

export default PdfPage;
