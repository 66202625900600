import React from "react";

const LocationItem = ({ therapist }) => {
  return (
    <>
      <div className="map-img-div">
        <img
          src="https://www.thestatesman.com/wp-content/uploads/2020/04/googl_ED.jpg"
          alt="location-img"
          // width="100px"
        />
      </div>
      <div className="phone-div">
        {therapist.address}
        Phone: {therapist.phone}
      </div>
      <style jsx>
        {`
          .map-img-div {
            width: 200px;
          }
          .phone-div {
            width: 50%;
            margin-left: 10px;
          }
          @media only screen and (max-width: 431px) {
            .map-img-div {
              width: 250px;
            }
            .phone-div {
              width: 80%;
              margin-left: 0px;
            }
          }
        `}
      </style>
    </>
  );
};

export default LocationItem;
