import React from "react";

const DetailItem = ({ icon: Icon, label, value }) => {
  return (
    <>
      <div className="flex-container margin-top--sm">
        <Icon fontSize="small" color="primary" />
        <p style={{ margin: "-1px 0px 0px 8px" }}>
          {window.innerWidth > 450 && label !== "Qualification: " && label}
          {value}
        </p>
      </div>
    </>
  );
};

export default DetailItem;
