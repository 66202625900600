import React from "react";
import { timeSlots } from "../../helper/data.helper";

const TimeslotSelect = ({ selectedTimeIndex, handleTimeClick }) => {
  return (
    <>
      <div
        style={{
          maxWidth: "400px",
          textAlign: "center",
          marginTop: "20px",
          // padding: "5px",
        }}
      >
        <ul
          className="flex-container list-items"
          style={{
            listStyleType: "none",
            padding: 0,
            marginTop: "0px",
          }}
        >
          {timeSlots.map((slot, index) => (
            <li
              style={{ marginLeft: "10px" }}
              key={slot}
              className={index === selectedTimeIndex ? "selected" : ""}
              onClick={() => handleTimeClick(slot, index)}
            >
              {slot}
            </li>
          ))}
        </ul>
      </div>
      <style jsx>
        {`
          div {
            color: black;
          }
          li {
            text-align: center;
            padding: 10px 30px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid #097eff;
            color: #097eff;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 12px;
            width: 80px;
          }
          li:hover {
            outline: white;
            color: white;
            background-color: #097eff;
          }
          .selected {
            background-color: #097eff;
            color: white;
          }
          .list-items {
            flex-wrap: wrap;
          }
        `}
      </style>
    </>
  );
};

export default TimeslotSelect;
