import React from "react";
import LocationItem from "./LocationItem";

const AddressDetails = ({ therapist }) => {
  return (
    <>
      <div className="flex-container map-div">
        <LocationItem therapist={therapist} />
      </div>
      <div className="flex-container  map-div" style={{ marginTop: "10px" }}>
        <LocationItem therapist={therapist} />
      </div>
      <style jsx>
        {`
          @media only screen and (max-width: 431px) {
            .map-div {
              flex-direction: column;
            }
          }
        `}
      </style>
    </>
  );
};

export default AddressDetails;
