import React from "react";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";

const PictureDetails = ({ therapist }) => {
  return (
    <>
      <div className="image-div">
        <img
          alt="profile"
          src={therapist.photo}
          // width={150}
          // height={150}
        />
      </div>
      {window.innerWidth < 500 && (
        <div style={{ marginLeft: "20px" }}>
          <div
            className=" flex-container name-div"
            // style={{ marginBottom: "-1px" }}
          >
            <p
              style={{
                fontSize: "medium",
                fontWeight: "700",
                color: "black",
                // marginBottom: "-15px",
              }}
            >
              {therapist.name
                ? therapist.name.length > 15
                  ? therapist.name.slice(0, 15) + "..."
                  : therapist.name
                : null}
            </p>
            <p
              className="margin-top--xs"
              // style={{ marginLeft: "20px" }}
            >
              <VideocamOutlinedIcon
                fontSize="small"
                color="primary"
                sx={{
                  backgroundColor: "rgb(216 230 253)",
                  padding: 0.2,
                  marginTop: "-5px",
                  borderRadius: "10%",
                }}
              />{" "}
              &nbsp;
              <ChairOutlinedIcon
                fontSize="small"
                color="primary"
                sx={{
                  backgroundColor: "rgb(216 230 253)",
                  padding: 0.2,
                  marginTop: "-5px",
                  borderRadius: "10%",
                }}
              />
            </p>
            {/* <p className="margin-top--xs">
                          {therapist.location
                            ? therapist.location.length > 20
                              ? therapist.location.slice(0, 20) + "..."
                              : therapist.location
                            : null}
                        </p> */}
          </div>
          <p
            style={{
              marginTop: "-5px",
            }}
          >
            <span
              style={{
                borderBottom: "1px solid lightgrey",
                paddingBottom: "7px",
                color: "black",
              }}
            >
              {therapist.title
                ? therapist.title.length > 25
                  ? therapist.title.slice(0, 25) + "..."
                  : therapist.title
                : null}
            </span>
          </p>
        </div>
      )}

      <style jsx>
        {`
          p {
            color: black;
          }
          .image-div {
            width: 200px;
            height: 200px;
            overflow: hidden;
            border-radius: 50%;
          }
          .image-div img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
          .name-div {
            justify-content: space-between;
            margin-bottom: 5px;
          }
          @media only screen and (max-width: 500px) {
            span,
            p {
              font-size: 13px;
            }
            .image-div {
              width: 90px;
              height: 90px;
            }
            .name-div {
              justify-content: space-between;
              // margin-left: 50px;
              width: 200px;
            }
          }
        `}
      </style>
    </>
  );
};

export default PictureDetails;
