import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";
import { reload } from "firebase/auth";

import { auth } from "../../../helper/firebase.helper";

const VerifyEmail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      reload(auth.currentUser);
      if (
        auth.currentUser?.emailVerified
      ) {
        const password = sessionStorage.getItem(auth.currentUser.email);
        auth
          .signInWithEmailAndPassword(auth.currentUser.email, password)
          .then(() => {
            toast.success("Email registered successfully!");
            navigate("/register/register-number");
            sessionStorage.removeItem(auth.currentUser.email);
          });
      } else {
        reload(auth.currentUser);
      }
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="verify">
      <h4>You&apos;re almost there</h4>
      <p>
        Thanks for signing up! You would see a verification link in your email
        (it might be in your spam folder). Verify your email to access DeTalks.
      </p>
      <style jsx>{`
        .verify {
          text-align: center;
          padding: 5% 10% 10% 10%;
          min-height: 80vh;
        }
      `}</style>
    </div>
  );
};

export default VerifyEmail;
