import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import { blogs } from "../../helper/data.helper";

const Blogs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 780);
  const [showAllBlogs, setShowAllBlogs] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 780);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const displayedBlogs = showAllBlogs ? blogs : blogs.slice(0, 3);

  return (
    <div className="margin-top--xl">
      <h2 style={{ fontWeight: "normal" }}>BLOG</h2>
      <h2 className="margin-top--xs">Educate Yourself for Mental Well-being</h2>
      <p>
        Empower yourself with our curated collection of self-help tips on mental
        well-being. Our blog is your go-to resource for valuable insights and
        practical advice to enhance your mental health and overall well-being.
      </p>
      <div className="carousel-container">
        {isMobile && (
          <Swiper
            modules={[Pagination]}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
          >
            {blogs?.map((blog) => (
              <SwiperSlide key={blog.id}>
                <div className="carousel-card margin-top--lg">
                  <Link
                    // to={`/therapist/${therapist.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className="image-div">
                      <img alt="blog-pic" src={blog.image} height={160} />
                    </div>
                    <div className="blog-details">
                      <h3 style={{ marginTop: "-6px" }}>{blog.title}</h3>
                      <p className="margin-top--sm">{blog.description}</p>
                      <div className="flex-container justify-between margin-top--sm">
                        <div className="flex-container">
                          {blog.professorPics.map((pic) => (
                            <img
                              key={pic}
                              src={pic}
                              height={35}
                              className="professor-pic"
                              alt="professor-pic"
                            />
                          ))}
                        </div>
                        <b className="duration-tag">{blog.duration}</b>
                      </div>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>

      <div className="flex-container list-div">
        {!isMobile &&
          displayedBlogs?.map((blog) => (
            <div key={blog.id} className="blog-div margin-top--lg">
              <Link
                // to={`/therapist/${therapist.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className="image-div">
                  <img alt="blog-pic" src={blog.image} height={160} />
                </div>
                <div className="blog-details">
                  <h3 style={{ marginTop: "-6px" }}>{blog.title}</h3>
                  <p className="margin-top--sm">{blog.description}</p>
                  <div className="flex-container justify-between margin-top--sm">
                    <div className="flex-container">
                      {blog.professorPics.map((pic) => (
                        <img
                          key={pic}
                          src={pic}
                          height={35}
                          className="professor-pic"
                          alt="professor-pic"
                        />
                      ))}
                    </div>
                    <b className="duration-tag">{blog.duration}</b>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>

      {!isMobile && blogs && blogs.length > 3 && (
        <Button
          // href=""
          variant="contained"
          color="primary"
          // size="small"

          sx={{ textTransform: "none", marginTop: "20px" }}
          onClick={() => setShowAllBlogs((prev) => !prev)}
        >
          {showAllBlogs ? "Show less" : "Explore more blogs"}
        </Button>
      )}

      <style jsx>{`
        .list-div {
          flex-wrap: wrap;
          justify-content: left;
        }
        .blog-div {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          // min-width: 280px;
          max-width: 320px;
          overflow: hidden;
          margin-right: 5%;
        }
        .blog-div:hover {
          background-color: #f4f4f4;
        }
        .carousel-card {
          border: 1px solid #7c7c7c;
          border-radius: 10px;
          // min-width: 280px;
          max-width: 320px;
          overflow: hidden;
          // margin-right: 2%;
        }
        .carousel-card:hover {
          background-color: #f4f4f4;
        }
        .carousel-container {
          max-width: 320px;
          margin: auto;
        }
        .image-div img {
          object-fit: cover;
        }
        .blog-details {
          padding: 20px;
        }

        .blog-details img {
          border-radius: 50%;
          margin-left: 5px;
        }
        .duration-tag {
          border-radius: 5px;
          padding: 5px 8px;
          background-color: rgb(234 234 234);
        }
        .professor-pic {
          width: unset !important;
        }
        @media only screen and (max-width: 1025px) {
          .list-div {
            justify-content: left;
          }
          .blog-div {
            margin-right: 5%;
          }
        }
      `}</style>
    </div>
  );
};

export default Blogs;
