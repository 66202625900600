import React from "react";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";

const ConsultationModes = () => {
  return (
    <>
      <span
        style={{
          backgroundColor: "rgb(216 230 253)",
          padding: "3px 6px",
          borderRadius: "5px",
        }}
      >
        <VideocamOutlinedIcon
          fontSize="small"
          color="primary"
          sx={{ marginBottom: "-3px" }}
        />
        &nbsp; Telehealth
      </span>

      <p className="margin-top--xs">Telephonic, Video Call</p>
      <p style={{ paddingTop: "30px" }}>
        <span
          style={{
            backgroundColor: "rgb(216 230 253)",
            padding: "3px 6px",
            borderRadius: "5px",
          }}
        >
          <ChairOutlinedIcon
            fontSize="small"
            color="primary"
            sx={{ marginBottom: "-3px" }}
          />
          &nbsp; In-person
        </span>
      </p>
      <style jsx>
        {`
          p,
          .main-div > span {
            color: black;
          }
        `}
      </style>
    </>
  );
};

export default ConsultationModes;
