import React, { useEffect, memo, useState, useCallback } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import EmailIcon from "@mui/icons-material/Email";
// import FacebookIcon from '@mui/icons-material/Facebook';
//import AppleIcon from '@mui/icons-material/Apple';
import { toast } from "react-hot-toast";
import style from "./LoginMethods.module.scss";
import { auth } from "../../../helper/firebase.helper";
import googleIcon from "../../../assets/google-icon.svg";
import Loader from "../../../components/loader/Loader";
import LoginMail from "../loginMail/LoginMail";
// import { checkUserData } from '../login.service';
import {
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  signInWithPopup,
  getIdTokenResult,
} from "firebase/auth";

// eslint-disable-next-line react/display-name
const LoginMethods = memo(() => {
  const setUser = useStoreActions((actions) => actions.setUser);
  const [isLoading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get("redirectUrl");

  const validateUser = useCallback(
    async (res) => {
      await getIdTokenResult(res.user, true).then((idTokenResult) => {
        if (idTokenResult.claims.email_verified && idTokenResult.claims.userType === 0) {
          setUser(res.user);
          toast("Logged in");
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            location.state && location.state.from
              ? navigate(location.state.from)
              : navigate("/homepage");
          }
        } else {
          toast.error("User does not exist. Please click Sign up to create an account.");
          auth.signOut();
        }
      });
    },
    [location, navigate, redirectUrl]
  );

  const signInGmail = async () => {
    setLoading(true);
    localStorage.setItem("isLoggingIn", true);
    const googleProvider = new GoogleAuthProvider();
    await signInWithPopup(auth, googleProvider, browserPopupRedirectResolver).then((res) => validateUser(res)).catch(err => console.log(err));
    setLoading(false);
  };

  const signInWithEmail = () => {
    setIsEmail(true);
  };

  return (
    <div className={style.page_wrapper}>
      <Helmet>
        <title>Login - DeTalks{""}</title>
      </Helmet>
      {isLoading ? <Loader /> : null}
      <div className={style.wrapper}>
        {/* <h1>Practitioner Sign in</h1> */}
        {isEmail ? (
          <LoginMail validateUser={validateUser} />
        ) : (
          <>
            <Button
              startIcon={
                <img className={style.logo} src={googleIcon} alt="Google" />
              }
              className={style.login_button}
              variant="contained"
              onClick={signInGmail}
            >
              Sign in with Google
            </Button>
            {/* <Button
              startIcon={
                <FacebookIcon
                  style={{
                    marginLeft: '15px',
                    fontSize: '25px',
                    color: '#4267B2',
                  }}
                />
              }
              className={style.login_button}
              variant='contained'
              onClick={signInWithFB}
            >
              Sign in with Facebook
            </Button> */}
            {/* <Button
              startIcon={
                <AppleIcon
                  style={{
                    marginLeft: '-13px',
                    fontSize: '25px'
                  }}
                />
              }
              className={style.login_button}
              variant='contained'
              onClick={signInWithApple}
            >
              Sign in with Apple
            </Button> */}
            <Button
              startIcon={
                <EmailIcon style={{ marginLeft: "-10px", fontSize: "25px" }} />
              }
              className={style.login_button}
              variant="contained"
              onClick={signInWithEmail}
            >
              Sign in with email
            </Button>
          </>
        )}
        <p className={style.create_account}>
          Don&apos;t have an account?{" "}
          <Link className={style.sign_up} to="/register/register-user">
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
});

export default LoginMethods;
