import React from "react";
import { IconButton } from "@mui/material";
//import {MenuItem} from '@mui/material/';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import cobrandinglogo from "../../assets/cobrandingLogo.png";
import ThaparLogo from "../../assets/ThaparLogo.png";
import styles from "./logocontainer.module.scss";
import { Menu } from "@mui/icons-material";
import { Link, useMatch } from "react-router-dom";
import { useStoreState } from "easy-peasy";

export default function Logo({ handleSidebar }) {
  const dashboard = useMatch("/dashboard/*");
  const logo = useStoreState((state) => state.logo);

  return (
    <div className={styles.logoContainer}>
      {dashboard ? (
        <div className={styles.actionButton}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleSidebar}
          >
            <Menu />
          </IconButton>
        </div>
      ) : null}
      <div className={styles.logo}>
        <Link to={"/homepage"}>
          <div className={styles.detalks}>
            <img src={logo} alt="DeTalks" />
          </div>
        </Link>
      </div>
    </div>
  );
}
