import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";

const Details = ({ therapist }) => {
  return (
    <>
      {window.innerWidth > 500 && (
        <>
          <div className="flex-container name-div">
            <p
              style={{
                fontSize: "larger",
                fontWeight: "700",
                color: "black",
                minWidth: "120px",
              }}
            >
              {therapist.name
                ? therapist.name.length > 20
                  ? therapist.name.slice(0, 20) + "..."
                  : therapist.name
                : null}
            </p>
            <p>
              <VideocamOutlinedIcon
                fontSize="small"
                color="primary"
                sx={{
                  backgroundColor: "rgb(216 230 253)",
                  padding: 0.2,
                  marginBottom: "-5px",
                  borderRadius: "10%",
                }}
              />{" "}
              &nbsp;
              <ChairOutlinedIcon
                fontSize="small"
                color="primary"
                sx={{
                  backgroundColor: "rgb(216 230 253)",
                  padding: 0.2,
                  marginBottom: "-5px",
                  borderRadius: "10%",
                }}
              />
            </p>
            <p>
              {therapist.location
                ? therapist.location.length > 20
                  ? therapist.location.slice(0, 20) + "..."
                  : therapist.location
                : null}
            </p>
          </div>
          <span
            style={{
              borderBottom: "1px solid lightgrey",
              paddingBottom: "7px",
              color: "black",
            }}
          >
            {therapist.title
              ? therapist.title.length > 25
                ? therapist.title.slice(0, 25) + "..."
                : therapist.title
              : null}
          </span>
        </>
      )}
      <div className="margin-top--md">
        <div className="flex-container margin-top--sm">
          <StarBorderIcon fontSize="small" color="primary" />
          <p style={{ margin: "-1px 0px 0px 5px" }}>
            {window.innerWidth > 450 && "Specialties: "}
            {therapist.specialties
              ? therapist.specialties.length > 64
                ? therapist.specialties.slice(0, 64) + "..."
                : therapist.specialties
              : null}
          </p>
        </div>
        <div className="flex-container margin-top--sm">
          <PeopleAltOutlinedIcon fontSize="small" color="primary" />
          <p style={{ margin: "-1px 0px 0px 5px" }}>
            {window.innerWidth > 450 && "Works with: "}
            {therapist.worksWith
              ? therapist.worksWith.length > 64
                ? therapist.worksWith.slice(0, 64) + "..."
                : therapist.worksWith
              : null}
          </p>
        </div>
        <div className="flex-container margin-top--sm">
          <LanguageIcon fontSize="small" color="primary" />
          <p style={{ margin: "-1px 0px 0px 5px" }}>
            {window.innerWidth > 450 && "Languages: "}
            {therapist.languages
              ? therapist.languages.length > 64
                ? therapist.languages.slice(0, 64) + "..."
                : therapist.languages
              : null}
          </p>
        </div>

        <p className="margin-top--sm">Next Available: 26th June, 7:30 pm</p>
      </div>
      <style jsx>
        {`
          p {
            color: black;
          }
          .name-div {
            justify-content: space-between;
            margin-bottom: 5px;
          }
          @media only screen and (max-width: 500px) {
            span,
            p {
              font-size: 13px;
            }
            .name-div {
              justify-content: space-between;
              // margin-left: 50px;
              width: 200px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Details;
