import { Box, Button, Drawer, MenuItem, Select } from "@mui/material";
import React from "react";
import { eventTypes, timeSlots } from "../../helper/data.helper";
import DateSelect from "./DateSelect";
import LocationSelect from "./LocationSelect";

const MobileViewPopup = ({
  isDrawerOpen,
  toggleDrawer,
  selectedEventType,
  handleEventTypeChange,
  selectedLocation,
  handleLocationChange,
  therapist,
  selectedDate,
  handleDateClick,
  selectedTimeIndex,
  handleTimeClick,
  formatDate,
}) => {
  return (
    <>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            top: "10%",
            borderRadius: "15px",
            padding: "0px 10px 10px 10px",
          },
        }}
      >
        <Box p={2}>
          <h4>Choose your session type.</h4>
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            value={selectedEventType}
            onChange={handleEventTypeChange}
            displayEmpty
            fullWidth
            sx={{ marginBottom: "8px", marginTop: "8px", maxWidth: "345px" }}
          >
            {/* <MenuItem value="" disabled sx={{ minWidth: "310px" }}>
              Select Event Type
            </MenuItem> */}
            {/* {eventTypes.map((type, index) => (
              <MenuItem key={index} value={type} sx={{ minWidth: "310px" }}>
                {type}
              </MenuItem>
            ))} */}
            {eventTypes.map((type, index) => {
              const [eventType, duration] = type.split(" - ");
              return (
                <MenuItem key={type} value={type} sx={{ minWidth: "340px" }}>
                  <span style={{ marginRight: "8px" }}>{eventType}</span>
                  <span
                    style={{
                      backgroundColor: "rgb(216 230 253)",
                      padding: "3px 6px",
                      borderRadius: "5px",
                    }}
                  >
                    {duration}
                  </span>
                </MenuItem>
              );
            })}
          </Select>

          <LocationSelect
            selectedLocation={selectedLocation}
            handleLocationChange={handleLocationChange}
            therapist={therapist}
          />

          <DateSelect
            selectedDate={selectedDate}
            handleDateClick={handleDateClick}
          />
          <ul
            className="flex-container list-items"
            style={{
              maxWidth: "400px",
              listStyleType: "none",
              padding: 0,
              marginTop: "20px",
            }}
          >
            {timeSlots.map((slot, index) => (
              <li
                style={{ marginLeft: "10px" }}
                key={slot}
                className={index === selectedTimeIndex ? "selected" : ""}
                onClick={() => handleTimeClick(slot, index)}
              >
                {slot}
              </li>
            ))}
          </ul>
          <div
            className="flex-container justify-center"
            style={{ maxWidth: "350px" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={toggleDrawer(false)}
              sx={{ textTransform: "none" }}
            >
              Book session for {formatDate(selectedDate)}
            </Button>
          </div>
        </Box>
      </Drawer>
      <style jsx>
        {`
          p,
          h3,
          h4,
          .main-div > span,
          div {
            color: black;
          }
          li {
            text-align: center;
            padding: 10px 30px;
            cursor: pointer;
            border-radius: 3px;
            border: 1px solid #097eff;
            color: #097eff;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 12px;
            width: 80px;
          }
          li:hover {
            outline: white;
            color: white;
            background-color: #097eff;
          }
          .selected {
            background-color: #097eff;
            color: white;
          }
          .list-items {
            flex-wrap: wrap;
          }
          @media only screen and (max-width: 350px) {
            li {
              padding: 10px 10px;
            }
          }
        `}
      </style>
    </>
  );
};

export default MobileViewPopup;
